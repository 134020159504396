import React from "react";
import { Link } from "gatsby";
import logo from "../img/logo-black.svg"; 
import logoWhite from "../img/logo.svg";
import EnquiryForm from "./signupform";
import { useHubspotForm } from "@aaronhayes/react-use-hubspot-form";
const Footer = () => {
  const { loaded, error, formCreated } = useHubspotForm({
    region: "na1",
    portalId: "19573488",
    formId: "1bc4eac9-1a8c-476e-8e4f-58cff8896631",
    target: "#my-hubspot-form",
  });
  return (
    <footer>
      <div className="signupBanner">
        <div className="container SuperWidth">
          <div className="row">
            <div className="col-md-4"></div>
            <div className="col-md-8 DesktopSignUp whitetext text-center">
              <img src={logoWhite} height="45"></img> <br />
              <br />
              <h2>Contact Us</h2>
              <br></br>
              <div>
                <br></br>
                <div id="my-hubspot-form"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="padding60">
        <div className="container SuperWidth">
          <div className="row">
            <div className="col-md-12">
              <img src={logo} height="40" alt="OiC App"></img>
              <br />
              <br />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 footerLayoutcol-1">
              <p>
                OiC is an online marketplace platform primarily built for the
                optical sector to ease the challenge of distributors/retailers
                under a single roof to digitally increase their efficiency
              </p>
              <div>

                <p>FOLLOW US ON </p>

                <ul className="socialLinks">
              
                  <li>
                    <a
                      href="https://www.facebook.com/OIC-105456311612773"
                      target="_blank"
                      rel="nofollow"
                    >
                      <i className="fa fa-facebook-official"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/oicapps/"
                      target="_blank"
                      rel="nofollow"
                    >
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/oicapps/"
                      target="_blank"
                      rel="nofollow"
                    >
                      <i className="fa fa-linkedin-square"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/OIC02395398"
                      target="_blank"
                      rel="nofollow"
                    >
                      <i className="fa fa-twitter-square"></i>
                    </a>
                  </li>
                </ul>

              </div>
              
            </div>

            <div className="col-md-2">
              <ul className="footerLinks">
                <li>
                  <Link to="/about-us">ABOUT US</Link>
                </li>
                <li>
                  <Link to="/#products">PRODUCTS</Link>
                </li>
                <li>
                  <Link to="/#blog">BLOGS</Link>
                </li>
                <li>
                  <Link to="/privacy-policy">PRIVACY POLICY</Link>
                </li>
              </ul>
            </div>
            <div className="col-md-3">
              <ul className="footerLinks">
                <li>
                  <Link to="/terms-conditions">TERMS & CONDITIONS</Link>
                </li>
                <li>
                  <Link to="/faqs">FAQ</Link>
                </li>
                <li>
                  <Link to="/sitemap">SITE MAP</Link>
                </li>
                <li>
                  <Link to="/press-kit">MEDIA</Link>
                </li>
              </ul>
            </div>
            <div className="col-md-3">
              <p>
                Polaris House,<br></br> No. 244, Anna Salai,<br></br> Chennai,
                Tamil Nadu 600006 <br></br>
              </p>
              <p>
                <br></br>
                <br></br>
                <b>Registered Address : </b>
                <br></br>
                ADHYAS SOFTWARE PRIVATE LIMITED<br></br>
                128, DEFENCE COLONY, <br></br>
                EKKATUTHANGAL, Chennai,<br></br>
                Tamil Nadu, 600032 <br></br> <br></br>
              </p>
              <p>
                <b>Phone :</b>
                <br></br> +91 97918 36596
              </p>
              <p>
                <b>Email :</b> Marketing@oicapps.com
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
