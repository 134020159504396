import React, { useState } from "react";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";
import "../styles/font-awesome.min.css";
import favicon from "../img/favicon.png";
import logo from "../img/logo.svg";
import logoBlack from "../img/logo-black.svg";
const Menu = () => {
  return (
    <ul className="navbar-nav mr-auto">
      <li className="nav-item">
        <Link
          to="/"
          className="nav-link teamLinksNav"
          style={{ fontSize: "0.9rem" }}
        >
          HOME
        </Link>
      </li>
      <li className="nav-item dropdown mr-auto">
        <Link
          to="/#products"
          className="nav-link teamLinksNav"
          style={{ fontSize: "0.9rem" }}
        >
          PRODUCTS
        </Link>
      </li>
      <li className="nav-item">
        <Link
          to="/about-us/"
          className="nav-link teamLinksNav"
          style={{ fontSize: "0.9rem" }}
        >
          ABOUT US
        </Link>
      </li>
      <li className="nav-item">
        <Link
          to="/#blog"
          className="nav-link teamLinksNav"
          style={{ fontSize: "0.9rem" }}
        >
          BLOG
        </Link>
      </li>

      <li className="nav-item">
        <Link
          to="/contact-us/"
          className="nav-link"
          style={{ fontSize: "0.9rem" }}
        >
          REACH US
        </Link>
      </li>
    </ul>
  );
};

const ActionMenu = () => {
  return (
    <ul className="navbar-nav ml-auto">
      {/*<li className="nav-item">
        <Link
          to="/"
          className="nav-link buttonStyleColor"
          style={{ fontSize: "0.9rem" }}
        >
          Start Free Demo
        </Link>
      </li> &nbsp;
      <li className="nav-item dropdown mr-auto">
        <Link
          to="/#products"
          className="nav-link buttonStyleColor"
          style={{ fontSize: "0.9rem" }}
        >
          Sign In
        </Link>
  </li>*/}
    </ul>
  );
};
const Navigation = () => {
  const [menuState, setmenuState] = useState("close");
  const MenuOpen = () => {
    if (menuState === "open") {
      setmenuState("close");
    } else {
      setmenuState("open");
    }
    //console.log(menuState)
  };
  const [scrollState, setscrollState] = useState("top");
  const handleScroll = () => {
    if (window.scrollY === 0) {
      setscrollState("top");
    } else if (window.scrollY !== 0) {
      setscrollState("onmoving");
    }
  };
  if (typeof window !== "undefined") {
    window.addEventListener("scroll", handleScroll);
  }

  return (
    <div>
      <Helmet>
        <html lang="en" />
        <meta charset="UTF-8" />
        <meta property="og:locale" content="en_US" />
        <meta http-equiv="X-UA-Compatible" content="ie=edge" />
        <link rel="icon" href={favicon} type="image/png" />
        <link rel="icon" href={favicon} type="img/x-icon"></link>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="robots" content="index,follow" />
        <meta name="robots" content="all" />
        <meta name="copyright" content="https://www.oicapps.com//" />
      </Helmet>
      <header className={"header " + scrollState}>
        <div className="container SuperWidth padding0">
          <nav className="navbar navbar-expand-lg navbar-light">
            <Link className="navbar-brand logo center-logo" to="/">
              <img src={logo} alt="OiC Tech" className="whitelogo" />
              <img src={logoBlack} alt="OiC Tech" className="blackLogo" />
            </Link>
            <button
              className="navbar-toggler"
              name="mobilenavigation"
              type="button"
              name="MobileNavigation"
              onClick={() => {
                MenuOpen("All");
              }}
            >
              <i className="fa fa-navicon"></i>
            </button>

            <div className="collapse navbar-collapse" id="navbarToggler">
              <Menu />
            </div>
            <div className="collapse navbar-collapse" id="navbarToggler">
              <ActionMenu />
            </div>
          </nav>
        </div>
      </header>
      <div className={"Nav mobile-only " + menuState}>
        <div className="text-right">
          <button
            className="navbar-toggler"
            name="MobileNavigation"
            type="button"
            onClick={() => {
              MenuOpen("All");
            }}
          >
            <i className="fa fa-close"></i>
          </button>
        </div>
        <Menu />

        <br></br>
        <Link className="navbar-brand logo" to="/">
          <img src={logo} alt="OiC Tech" />
        </Link>
      </div>
    </div>
  );
};

export default Navigation;
