import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Navigation from "./navigation";
import "../styles/font-awesome.min.css";
import favicon from "../img/favicon.png";
const Header = () => {
  const [didMount, setDidMount] = useState(false);

  useEffect(() => {
    setDidMount(true);
    return () => setDidMount(false);
  }, []);

  if (!didMount) {
    return null;
  }

  return (
    
     
      <Navigation></Navigation>
   
  );
};

export default Header;
